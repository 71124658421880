export const STORAGE_APP = 'app';
export const STORAGE_BOOK_READER_SETTINGS = 'bookReaderSettings';
export const STORAGE_KEY_USER_LOCATION = 'userLocation';
export const STORAGE_KEY_USER = 'user';
export const STORAGE_KEY_GUEST_BOOK_READ_COUNTER = 'guestBookReadCounter';
export const STORAGE_KEY_DEFAULT_PROJECT = 'defaultProject';
export const STORAGE_KEY_PROFILE = 'profile';
export const STORAGE_KEY_LANGUAGE = 'language';
export const STORAGE_BOOK_FILTERS = 'bookFilters';
export const STORAGE_KEY_ACTIVITY_CATEGORIES = 'activityCategories';
export const STORAGE_KEY_FLAGS = 'flags';
export const STORAGE_KEY_SEARCH = 'search';
