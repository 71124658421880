import { defaultIntegrations, init as browserInit } from '@sentry/browser';
import { Hub, makeMain } from '@sentry/core';
import { DeviceContext, EventOrigin, Release, SdkInfo } from './integrations';
import { createCapacitorRewriteFrames } from './integrations/rewriteframes';
import { CapacitorScope } from './scope';
import { DEFAULT_BUFFER_SIZE, makeNativeTransport } from './transports/native';
import { makeUtf8TextEncoder } from './transports/TextEncoder';
import { NATIVE } from './wrapper';
/**
 * Initializes the Capacitor SDK alongside a sibling Sentry SDK
 * @param options Options for the SDK
 * @param originalInit The init function of the sibling SDK, leave blank to initialize with `@sentry/browser`
 */
export function init(passedOptions, originalInit = browserInit) {
  var _a, _b, _c;
  const finalOptions = Object.assign({
    enableAutoSessionTracking: true,
    enableWatchdogTerminationTracking: true,
    enableCaptureFailedRequests: false
  }, passedOptions);
  if (finalOptions.enabled === false || NATIVE.platform === 'web') {
    finalOptions.enableNative = false;
    finalOptions.enableNativeNagger = false;
  } else {
    // keep the original value if user defined it.
    (_a = finalOptions.enableNativeNagger) !== null && _a !== void 0 ? _a : finalOptions.enableNativeNagger = true;
    (_b = finalOptions.enableNative) !== null && _b !== void 0 ? _b : finalOptions.enableNative = true;
  }
  const capacitorHub = new Hub(undefined, new CapacitorScope());
  makeMain(capacitorHub);
  finalOptions.defaultIntegrations = [...defaultIntegrations, createCapacitorRewriteFrames(), new Release(), new SdkInfo(), new EventOrigin()];
  if (finalOptions.enableNative) {
    finalOptions.defaultIntegrations.push(new DeviceContext());
    if (!passedOptions.transport && NATIVE.platform !== 'web') {
      finalOptions.transport = passedOptions.transport || makeNativeTransport;
      finalOptions.transportOptions = Object.assign(Object.assign({
        textEncoder: makeUtf8TextEncoder()
      }, (_c = passedOptions.transportOptions) !== null && _c !== void 0 ? _c : {}), {
        bufferSize: DEFAULT_BUFFER_SIZE
      });
    }
  }
  const browserOptions = Object.assign(Object.assign({}, finalOptions), {
    autoSessionTracking: NATIVE.platform === 'web' && finalOptions.enableAutoSessionTracking
  });
  const mobileOptions = Object.assign(Object.assign({}, finalOptions), {
    enableAutoSessionTracking: NATIVE.platform !== 'web' && finalOptions.enableAutoSessionTracking
  });
  // We first initialize the NATIVE SDK to avoid the Javascript SDK to invoke any
  // feature from the NATIVE SDK without the options being set.
  // eslint-disable-next-line @typescript-eslint/no-floating-promises
  void NATIVE.initNativeSdk(mobileOptions);
  originalInit(browserOptions);
}
/**
 * If native client is available it will trigger a native crash
 * Use this only for testing purposes
 */
export function nativeCrash() {
  NATIVE.crash();
}
