import { Injectable, OnDestroy } from '@angular/core';
import * as Sentry from '@sentry/capacitor';
import * as SentrySibling from '@sentry/angular-ivy';
import { environment } from '../../environments/environment';
import pkg from '../../../package.json';
import { UserState } from '../store/user.state';
import { Subject, takeUntil } from 'rxjs';
import { Platform } from '@ionic/angular/standalone';

import { PreferencesService } from './preferences.service';
import { SeverityLevel } from '@sentry/capacitor';
import { runtimeEnvironment } from '../../environments/environment.r';
import {
  STORAGE_APP,
  STORAGE_BOOK_FILTERS,
  STORAGE_BOOK_READER_SETTINGS,
  STORAGE_KEY_ACTIVITY_CATEGORIES,
  STORAGE_KEY_DEFAULT_PROJECT,
  STORAGE_KEY_FLAGS,
  STORAGE_KEY_LANGUAGE,
  STORAGE_KEY_PROFILE,
  STORAGE_KEY_USER,
  STORAGE_KEY_USER_LOCATION,
} from '../store/constants';
import { HttpErrorResponse } from '@angular/common/http';
import { NetworkService } from './network.service';

interface SentryTagsPayload {
  context: string;
  feature?: Feature;
}

interface SentryPayloadExtraData extends SentryTagsPayload {
  [key: string]: unknown;
}

export enum Feature {
  APP_INIT = 'App initialisation',
  DATE_FORMAT = 'Date management',
  READER = 'Book reader',
  ACTIVITY = 'Activity',
  PROJECT = 'Project',
  PROFILE = 'Profile',
  SHELF = 'Shelf',
  DATA_MIGRATION = 'Data Migration',
}

interface SentryEventPayload {
  content: Error | string | unknown; // error / error message from a caught exception
  extraData: SentryPayloadExtraData; // object containing custom-tags and any extra information helpful in debugging
}

@Injectable({ providedIn: 'root' })
export class LoggerService implements OnDestroy {
  constructor(
    private platform: Platform,
    private userState: UserState,
    private preferencesService: PreferencesService,
    private networkService: NetworkService,
  ) {}

  private destroyed$ = new Subject<void>();

  initialize(): void {
    if (runtimeEnvironment.SENTRY_ENABLED) {
      this.initializeSentry();
    }
  }

  fatal(content: Error | string, extraData: SentryPayloadExtraData): void {
    this.trackSentryEvent({ content, extraData }, 'fatal');
  }

  error(content: Error | HttpErrorResponse | string, extraData: SentryPayloadExtraData): void {
    // Ignore http 0 response coming from manual loggerService.error calls.
    // These errors is thrown when the user has a bad network connection
    if (content instanceof HttpErrorResponse && content.status === 0) {
      return;
    }
    this.trackSentryEvent({ content, extraData }, 'error');
  }

  warning(content: Error | string, extraData: SentryPayloadExtraData): void {
    this.trackSentryEvent({ content, extraData }, 'warning');
  }

  private trackSentryEvent(event: SentryEventPayload, severity: SeverityLevel): void {
    try {
      switch (severity) {
        case 'fatal' as SeverityLevel:
        case 'error' as SeverityLevel:
          console.error(event.content, event.extraData.context);
          break;
        case 'warning' as SeverityLevel:
          console.warn(event.content, event.extraData.context);
          break;
        case 'log' as SeverityLevel:
          console.log(event.content, event.extraData.context);
          break;
        default:
          console.error(event.content, event.extraData.context);
          break;
      }
    } catch (cError) {
      console.log('Cannot log sentry event to console!');
    }

    if (runtimeEnvironment.SENTRY_ENABLED) {
      Sentry.withScope(scope => {
        scope.setLevel(severity);
        scope.setTag('feature', event.extraData?.feature || 'Default');

        Sentry.captureMessage(this.stringifyError(event.content), scope);
      });
    }
  }

  private stringifyError(error: Error | string | unknown): string {
    return typeof error === 'string' ? error : JSON.stringify(error);
  }

  private initializeSentry(): void {
    Sentry.init(
      {
        normalizeDepth: 4,
        dsn: environment.sentryDsn,
        environment: environment.envName,
        dist: pkg.version,
        release: 'BookSmart-' + pkg.version + '-' + runtimeEnvironment.BUILD_NUMBER,
        tracesSampleRate: 1.0,
        integrations: [SentrySibling.browserTracingIntegration()],
        beforeSend: async event => {
          event.extra = {
            [STORAGE_APP]: await this.preferencesService.getDebugData(STORAGE_APP),
            [STORAGE_BOOK_READER_SETTINGS]: await this.preferencesService.getDebugData(STORAGE_BOOK_READER_SETTINGS),
            [STORAGE_KEY_USER_LOCATION]: await this.preferencesService.getDebugData(STORAGE_KEY_USER_LOCATION),
            [STORAGE_KEY_USER]: await this.preferencesService.getDebugData(STORAGE_KEY_USER),
            [STORAGE_KEY_DEFAULT_PROJECT]: await this.preferencesService.getDebugData(STORAGE_KEY_DEFAULT_PROJECT),
            [STORAGE_KEY_PROFILE]: await this.preferencesService.getDebugData(STORAGE_KEY_PROFILE),
            [STORAGE_KEY_LANGUAGE]: await this.preferencesService.getDebugData(STORAGE_KEY_LANGUAGE),
            [STORAGE_BOOK_FILTERS]: await this.preferencesService.getDebugData(STORAGE_BOOK_FILTERS),
            [STORAGE_KEY_ACTIVITY_CATEGORIES]: await this.preferencesService.getDebugData(STORAGE_KEY_ACTIVITY_CATEGORIES),
            [STORAGE_KEY_FLAGS]: await this.preferencesService.getDebugData(STORAGE_KEY_FLAGS),
          };
          return event;
        },
      },
      SentrySibling.init,
    );

    Sentry.setTags({
      platform: this.getPlatform(),
      build: runtimeEnvironment.BUILD_NUMBER,
    });

    this.networkService.connectionType$.pipe(takeUntil(this.destroyed$)).subscribe(connectionType => {
      Sentry.setTag('connection', connectionType);
    });
  }

  initializeSentryUser(): void {
    if (runtimeEnvironment.SENTRY_ENABLED) {
      this.userState.user$.pipe(takeUntil(this.destroyed$)).subscribe(user => {
        Sentry.setUser({
          id: user.id,
          userType: user.userType,
          isLegacy: user.isLegacy?.toString(),
        });
      });
    }
  }

  private getPlatform(): 'ios' | 'web' | 'android' {
    if (this.platform.is('ios')) {
      return 'ios';
    } else if (this.platform.is('android')) {
      return 'android';
    } else {
      return 'web';
    }
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }
}
