// eslint-disable-next-line
export const sanitize = (target: any, keys: Array<string>) => {
  if (typeof target === 'object') {
    for (const key in target) {
      if (typeof target[key] === 'object') {
        sanitize(target[key], keys);
      } else {
        if (keys.includes(key)) {
          target[key] = '[private]';
        }
      }
    }
  }
  return target;
};
