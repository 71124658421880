import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { UserLocation } from '../store/user-location.state';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class UserLocationProvider {
  constructor(private http: HttpClient) {}

  getLocationData(): Observable<UserLocation> {
    return this.http.get<UserLocation>(environment.locationApi);
  }
}
