import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { UserLocationState } from '../store/user-location.state';
import { Project, Projects } from '../models/projects.model';
import { Observable } from 'rxjs';

import { UrlBuilder } from '../utils/url-builder';

enum ApiEndpoint {
  CheckProjectCode = '/client/projects/:code',
  SetProjectCode = '/settings',
  GetProjects = '',
}

@Injectable({ providedIn: 'root' })
export class ProjectProvider {
  constructor(
    private http: HttpClient,
    private userLocationState: UserLocationState,
  ) {}

  getProjects(limit?: number): Observable<Projects> {
    const url = new UrlBuilder(ApiEndpoint.GetProjects)
      .setQueryParams({ country: this.userLocationState.userLocation$.value.countryCode, limit, schema: 'minimal' })
      .getUrl();

    return this.http.get<Projects>(environment.projectBaseUrl + url);
  }

  getProject(code: string): Observable<Project> {
    const url = new UrlBuilder(ApiEndpoint.CheckProjectCode)
      .setUrlParams({ code })
      .setQueryParams({ country: this.userLocationState.userLocation$.value.countryCode, schema: 'minimal' })
      .getUrl();

    return this.http.get<Project>(environment.worldreaderBaseUrl + url);
  }

  setProjectCode(code: string): Observable<void> {
    const url = new UrlBuilder(ApiEndpoint.SetProjectCode).setQueryParams({ projectCodes: [code] }).getUrl();

    return this.http.get<void>(environment.usersBaseUrl + url);
  }
}
