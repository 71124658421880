import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { User, UserType } from '../store/user.state';
import { UrlBuilder } from '../utils/url-builder';

enum ApiEndpoint {
  GetGuestUser = '/auth/guest-user',
}

@Injectable({ providedIn: 'root' })
export class GuestUserProvider {
  constructor(private http: HttpClient) {}

  getGuestUser(ip: string): Observable<User> {
    const url = new UrlBuilder(ApiEndpoint.GetGuestUser).setQueryParams({ ip }).getUrl();

    return this.http.get<{ guestId: string; jwt: string }>(environment.usersBaseUrl + url).pipe(
      map(user => ({
        id: user.guestId,
        jwt: user.jwt,
        userType: UserType.GUEST,
      })),
    );
  }
}
