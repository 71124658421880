import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { User, UserType } from '../store/user.state';
import { UrlBuilder } from '../utils/url-builder';

enum ApiEndpoint {
  LoginMoya = '/auth/moya',
}

@Injectable({ providedIn: 'root' })
export class MoyaUserProvider {
  constructor(private http: HttpClient) {}

  loginWithMoya(moyaId: string, country: string): Observable<User> {
    const url = new UrlBuilder(ApiEndpoint.LoginMoya).setQueryParams({ country }).getUrl();
    return this.http.post<{ jwt: string; userId: string }>(environment.usersBaseUrl + url, { moyaId }).pipe(
      map(moyaUser => ({
        jwt: moyaUser.jwt,
        id: moyaUser.userId,
        userType: UserType.MOYA,
      })),
    );
  }
}
