import { Injectable } from '@angular/core';
import { Platform } from '@ionic/angular/standalone';
import { BehaviorSubject } from 'rxjs';
import { SplashScreen } from '@capacitor/splash-screen';

@Injectable({ providedIn: 'root' })
export class SplashScreenService {
  constructor(private platform: Platform) {}

  private isWeb = !this.platform.is('cordova');

  displayWebSplashScreen$ = new BehaviorSubject(true);
  displayErrorSplashScreen$ = new BehaviorSubject(false);
  displaySplashNetworkError = new BehaviorSubject(false);

  showSplashScreen(): void {
    if (this.isWeb) {
      this.displayWebSplashScreen$.next(true);
    } else {
      void SplashScreen.show();
    }
  }

  hideSplashScreen(): void {
    if (this.isWeb) {
      this.displayWebSplashScreen$.next(false);
    } else {
      void SplashScreen.hide();
    }
  }

  showSplashError(): void {
    this.hideSplashScreen();
    this.displayErrorSplashScreen$.next(true);
  }

  showSplashNetworkError(): void {
    this.hideSplashScreen();
    this.displayErrorSplashScreen$.next(false);
    this.displaySplashNetworkError.next(true);
  }
}
