;
/**
 * Return the current webview url if found.
 */
export function getCurrentServerUrl() {
  if (typeof self !== 'undefined') {
    return self.WEBVIEW_SERVER_URL;
  }
  return undefined;
}
