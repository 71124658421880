import { Injectable } from '@angular/core';
import { ToastController } from '@ionic/angular/standalone';
import { TranslateService } from '@ngx-translate/core';
import { AppService } from './app.service';
import { Router } from '@angular/router';

export enum ToastType {
  Informative = 'toast-informative',
  Positive = 'toast-positive',
  Negative = 'toast-negative',
}
@Injectable({
  providedIn: 'root',
})
export class ToastService {
  constructor(
    private toastController: ToastController,
    private translateService: TranslateService,
    private appService: AppService,
    private router: Router,
  ) {}

  private displayedToastElement: HTMLIonToastElement | null = null;
  private offlineWarningToast?: HTMLIonToastElement;

  async present(params: { message: string; type: ToastType; icon?: string; displayClose: boolean; extraButton?: { title: string } }): Promise<{
    cancelAction?: boolean;
  }> {
    if (this.displayedToastElement) {
      await this.displayedToastElement?.dismiss();
    }

    const noHeader = this.hasPageHeader() ? '' : 'no-header';

    const toast = await this.toastController.create({
      message: params.message,
      duration: 3000,
      position: 'top',
      cssClass: [noHeader, params.type, window.innerWidth > 768 ? 'desktop' : 'mobile'],
      icon: params.icon,
      buttons: this.getButtons(params.displayClose, params.extraButton || null),
    });

    await toast.present();

    this.displayedToastElement = toast;

    const dismissData = await toast.onDidDismiss();

    return dismissData?.data;
  }

  async presentOfflineWarningToast(): Promise<void> {
    this.offlineWarningToast = await this.toastController.create({
      message: this.translateService.instant('PWA_offline_error_checkConnection'),
      position: 'top',
      cssClass: [ToastType.Negative, window.innerWidth > 768 ? 'desktop' : 'mobile'],
    });

    await this.offlineWarningToast.present();
  }

  async dismissOfflineWarningToast(): Promise<void> {
    if (this.offlineWarningToast) {
      await this.offlineWarningToast.dismiss();
    }
  }

  private getButtons(
    closeButton: boolean,
    extraButton: { title: string } | null,
  ): Array<{ icon: string; htmlAttributes: { 'aria-label': string } } | { text: string; cssClass: string; handler: () => Promise<boolean> }> {
    const buttons = [];

    if (closeButton) {
      buttons.unshift({
        icon: 'assets/icon/white-close.svg',
        htmlAttributes: {
          'aria-label': 'close',
        },
      });
    }

    if (extraButton) {
      buttons.unshift({
        text: extraButton.title,
        cssClass: 'extra-button',
        handler: () => this.toastController.dismiss({ cancelAction: true }),
      });
    }

    return buttons;
  }

  private hasPageHeader(): boolean {
    const bookDetailPageRegex = /^\/book\/[^/]+\/details$/;

    return !((this.appService.isMobile$?.value && bookDetailPageRegex.test(this.router.url)) || this.router.url === '/profile');
  }
}
