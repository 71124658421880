import { PreferencesService } from '../services/preferences.service';
import { Injectable } from '@angular/core';
import { BehaviorSubject, firstValueFrom } from 'rxjs';
import { UserLocationProvider } from '../providers/user-location.provider';
import { STORAGE_BOOK_FILTERS, STORAGE_KEY_DEFAULT_PROJECT, STORAGE_KEY_USER_LOCATION } from './constants';

export interface UserLocation {
  city: string;
  countryCode: string;
  district: string;
  query: string;
  regionName: string;
  zip: string;
}

@Injectable({ providedIn: 'root' })
export class UserLocationState {
  constructor(
    private preferenceService: PreferencesService,
    private userLocationProvider: UserLocationProvider,
  ) {}

  private readonly STORAGE_KEY = STORAGE_KEY_USER_LOCATION;

  userLocation$!: BehaviorSubject<UserLocation>;

  async initialize(): Promise<void> {
    const storedLocation = await this.get();
    const newLocation = await firstValueFrom(this.userLocationProvider.getLocationData());

    // If the country change, we need to set again the default project state & the book filters state
    if (storedLocation?.countryCode !== newLocation?.countryCode) {
      await this.preferenceService.remove(STORAGE_KEY_DEFAULT_PROJECT);
      await this.preferenceService.remove(STORAGE_BOOK_FILTERS);
    }

    await this.set(newLocation);

    await Promise.resolve();
  }

  private async get(): Promise<UserLocation | null> {
    return JSON.parse(<string>(await this.preferenceService.get(this.STORAGE_KEY)).value);
  }

  async set(settings: Partial<UserLocation>): Promise<void> {
    const updatedOptions: UserLocation = {
      ...this.userLocation$?.value,
      ...settings,
    };

    if (this.userLocation$) {
      this.userLocation$.next(updatedOptions);
    } else {
      this.userLocation$ = new BehaviorSubject<UserLocation>(updatedOptions);
    }

    await this.preferenceService.set(this.STORAGE_KEY, JSON.stringify(updatedOptions));
  }
}
