import { Injectable } from '@angular/core';
import { GetResult, Preferences } from '@capacitor/preferences';
import { sanitize } from '../utils/sanitize-object';

@Injectable({ providedIn: 'root' })
export class PreferencesService {
  async get(key: string): Promise<GetResult> {
    return await Preferences.get({ key });
  }

  async getDebugData(key: string): Promise<string | null> {
    let data = (await this.get(key)).value;

    if (data) {
      data = sanitize(JSON.parse(data), ['query', 'city', 'zip', 'regionName', 'jwt', 'name', 'gender']);
    }

    return data;
  }

  async set(key: string, value: string): Promise<void> {
    await Preferences.set({ key, value });
  }

  async clear(): Promise<void> {
    return await Preferences.clear();
  }

  async remove(key: string): Promise<void> {
    return await Preferences.remove({ key });
  }
}
