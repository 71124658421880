import { PreferencesService } from '../services/preferences.service';
import { Injectable } from '@angular/core';
import { BehaviorSubject, firstValueFrom } from 'rxjs';
import { STORAGE_KEY_DEFAULT_PROJECT } from './constants';
import { ProjectProvider } from '../providers/project.provider';
import { Project, Projects, StoredProject } from '../models/projects.model';
import { Feature, LoggerService } from '../services/logger.service';
import { Platform } from '@ionic/angular/standalone';
import { HttpErrorResponse } from '@angular/common/http';

@Injectable({ providedIn: 'root' })
export class DefaultProjectState {
  constructor(
    private preferenceService: PreferencesService,
    private projectProvider: ProjectProvider,
    private loggerService: LoggerService,
    private platform: Platform,
  ) {}

  private readonly STORAGE_KEY = STORAGE_KEY_DEFAULT_PROJECT;
  defaultProject$!: BehaviorSubject<StoredProject>;

  async initialize(): Promise<void> {
    let storedDefaultProject = await this.get();

    // First app access
    if (!storedDefaultProject) {
      // Try to get the project id from BookSmart 1.0 - Web only!
      const oldProject: Project | null = await this.getOldProject();

      if (oldProject) {
        await this.set(oldProject);
      } else {
        const projects: Projects = await firstValueFrom(this.projectProvider.getProjects(1));

        if (!projects.projects.length) {
          this.loggerService.fatal('DefaultProjectState::initialize', {
            feature: Feature.APP_INIT,
            context: 'No default project retreived from the API',
          });

          throw Error('No default project retreived from the API');
        }

        const project = projects.projects[0];

        await this.set(project);
      }

      // If no booksmart 1.0 project, set default project
    } else {
      // Since https://worldreader.atlassian.net/browse/B20CP-493, we need the isDefault key. Pulling the API again if it's not the case
      if (!('isDefault' in storedDefaultProject)) {
        const project = await firstValueFrom(this.projectProvider.getProject((storedDefaultProject as StoredProject).code));
        storedDefaultProject = this.getStoredProject(project);
        await this.set(project);
      }

      this.defaultProject$ = new BehaviorSubject<StoredProject>(storedDefaultProject);
    }

    return Promise.resolve();
  }

  private async get(): Promise<StoredProject | null> {
    return JSON.parse(<string>(await this.preferenceService.get(this.STORAGE_KEY)).value);
  }

  async set(project: Project): Promise<void> {
    // We store only part of the project in the local storage due to memory limitations
    const storedProject: StoredProject = this.getStoredProject(project);

    if (this.defaultProject$) {
      this.defaultProject$.next(storedProject);
    } else {
      this.defaultProject$ = new BehaviorSubject<StoredProject>(storedProject);
    }

    await this.preferenceService.set(this.STORAGE_KEY, JSON.stringify(storedProject));
  }

  async reset(): Promise<void> {
    await this.preferenceService.remove(this.STORAGE_KEY);
    await this.initialize();
  }

  private async getOldProject(): Promise<Project | null> {
    try {
      // BookSmart 1.0 project migration
      if (!this.platform.is('cordova')) {
        const oldBookSmartData = localStorage.getItem('WZRK_PR');

        if (oldBookSmartData) {
          const decodedUriData = decodeURIComponent(oldBookSmartData);
          const parsedData = JSON.parse(decodedUriData);
          const oldProjectCode = parsedData.project_id as string;

          return await firstValueFrom(this.projectProvider.getProject(oldProjectCode)).catch((error: HttpErrorResponse) => {
            if (error.status === 404) {
              localStorage.removeItem('WZRK_PR');
              return null;
            } else {
              throw error;
            }
          });
        }
      }
    } catch (error) {
      this.loggerService.error('DefaultProjectState::getOldProject', {
        feature: Feature.DATA_MIGRATION,
        context: 'BookSmart 1.0 Project code couldnt be parsed ' + localStorage.getItem('WZRK_PR'),
      });
    }

    localStorage.removeItem('WZRK_PR');

    return null;
  }

  private getStoredProject(project: Project): StoredProject {
    return {
      id: project.id,
      name: project.name,
      code: project.code,
      grades: project.grades,
      organizationCode: project.organizationCode,
      programId: project.programId,
      isDefault: project.isDefault || false,
    };
  }
}
