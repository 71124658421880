import { Injectable } from '@angular/core';
import { ConnectionStatus, ConnectionType, Network } from '@capacitor/network';
import { BehaviorSubject, filter, firstValueFrom, take } from 'rxjs';
import { SplashScreenService } from './splash-screen.service';
import { ToastService } from './toast.service';

@Injectable({ providedIn: 'root' })
export class NetworkService {
  constructor(
    private splashScreenService: SplashScreenService,
    private toastService: ToastService,
  ) {}

  private initialized = false;

  isOnline$!: BehaviorSubject<boolean>;
  connectionType$!: BehaviorSubject<ConnectionType>;

  async initialize(): Promise<void> {
    const networkStatus = await Network.getStatus();
    this.isOnline$ = new BehaviorSubject<boolean>(networkStatus.connected);
    this.connectionType$ = new BehaviorSubject<ConnectionType>(networkStatus.connectionType);

    void Network.addListener('networkStatusChange', (status: ConnectionStatus) => {
      if (this.isOnline$.value !== status.connected) {
        this.isOnline$.next(status.connected);
        this.connectionType$.next(status.connectionType);
        this.initialized && this.toggleOfflineToast(status.connected);
      }
    });

    if (!this.isOnline$.value) {
      this.splashScreenService.showSplashNetworkError();
      await firstValueFrom(
        this.isOnline$.pipe(
          filter(online => online),
          take(1),
        ),
      );
      this.splashScreenService.showSplashScreen();
      location.reload();
    }
    this.initialized = true;

    return Promise.resolve();
  }

  private toggleOfflineToast(online: boolean): void {
    online ? void this.toastService.dismissOfflineWarningToast() : void this.toastService.presentOfflineWarningToast();
  }
}
