import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import * as crypto from 'crypto-js';
import { Capacitor } from '@capacitor/core';
import { Platform } from '@ionic/angular/standalone';

@Injectable({ providedIn: 'root' })
export class HttpClientService {
  constructor(private platform: Platform) {}

  generateHeaders(reqPath: string): { hash: string; client: string; timestamp: string } {
    const reqTime = Date.now();
    const client = this.getClientCodeAndToken();

    return {
      hash: this.generateHash(reqPath, reqTime, client.clientToken).toString(),
      client: client.clientCode,
      timestamp: reqTime.toString(),
    };
  }

  getClientCodeAndToken(): { clientCode: string; clientToken: string } {
    if (this.platform.is('desktop')) {
      return {
        clientCode: environment.deskTopClientCode,
        clientToken: environment.deskTopClientToken,
      };
    } else if (this.platform.is('mobileweb')) {
      return {
        clientCode: environment.webClientCode,
        clientToken: environment.webClientToken,
      };
    } else if (Capacitor.getPlatform() === 'android') {
      return {
        clientCode: environment.androidClientCode,
        clientToken: environment.androidClientToken,
      };
    } else {
      return {
        clientCode: environment.iosClientCode,
        clientToken: environment.iosClientToken,
      };
    }
  }

  private generateHash(reqPath: string, reqTime: number, token: string): CryptoJS.lib.WordArray {
    return crypto.SHA256(`${reqPath}${reqTime}${token}`);
  }

  decryptResponse(encryptedData: string, aesKey: string): string {
    const bytes = CryptoJS.AES.decrypt(encryptedData, aesKey);
    return bytes.toString(CryptoJS.enc.Utf8);
  }
}
