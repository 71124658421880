{
  "name": "book-smart",
  "version": "5.1.7",
  "author": "Worldreader",
  "homepage": "https://www.worldreader.org/",
  "scripts": {
    "serve": "ionic serve",
    "livereload:android": "ionic capacitor run android -l --external",
    "livereload:ios": "ionic capacitor run ios -l --external",
    "prebuild": "sh build-scripts/replace-env-file.sh",
    "build": "sh build-scripts/set-runtime-env-var.sh && ionic build -c $BUILD_ENV --optimization",
    "build:dev": "export BUILD_ENV=\"development\" && npm run build",
    "build:qa": "export BUILD_ENV=\"qa\" && npm run build",
    "build:beta": "export BUILD_ENV=\"beta\" && npm run build",
    "build:prod": "export BUILD_ENV=\"production\" && npm run build",
    "cy:open": "npx cypress open",
    "cy:run": "npx cypress run --browser chrome",
    "eslint": "eslint ./src --fix",
    "lint:fix": "eslint ./src --fix",
    "test": "ng test",
    "prepare": "husky",
    "lint": "ng lint --fix",
    "format": "npm run lint:fix && prettier --write .",
    "stylelint": "stylelint \"src/**/*.scss\" --fix",
    "pre-commit": "npm run format && npm run lint && npm run stylelint",
    "prettier": "npx prettier --write .",
    "appflow:build": ". build-scripts/get-app-version.sh && . build-scripts/set-build-number.sh && if [ \"$CI_PLATFORM\" != \"web\" ]; then npx trapeze run appflow.yml -y --$CI_PLATFORM; fi && npm run build --configuration=$BUILD_ENV && npm run sentry:sourcemaps",
    "sentry:sourcemaps": "sentry-cli sourcemaps inject --org worldreader --project booksmart ./www && sentry-cli sourcemaps upload --org worldreader --project booksmart ./www"
  },
  "lint-staged": {
    "**/*": "prettier --ignore-unknown --write",
    "**/*.{ts,js,html}": [
      "eslint"
    ]
  },
  "private": true,
  "dependencies": {
    "@angular/animations": "^17.3.2",
    "@angular/common": "^17.3.2",
    "@angular/compiler": "^17.3.2",
    "@angular/core": "^17.3.2",
    "@angular/forms": "^17.3.2",
    "@angular/platform-browser": "^17.3.2",
    "@angular/platform-browser-dynamic": "^17.3.2",
    "@angular/router": "^17.3.2",
    "@aws-sdk/client-pinpoint": "^3.501.0",
    "@capacitor/android": "^6.0.0",
    "@capacitor/app": "^6.0.0",
    "@capacitor/browser": "^6.0.0",
    "@capacitor/core": "^6.0.0",
    "@capacitor/device": "^6.0.0",
    "@capacitor/haptics": "^6.0.0",
    "@capacitor/ios": "^6.0.0",
    "@capacitor/keyboard": "^6.0.0",
    "@capacitor/network": "^6.0.0",
    "@capacitor/preferences": "^6.0.0",
    "@capacitor/share": "^6.0.0",
    "@capacitor/splash-screen": "^6.0.0",
    "@capacitor/status-bar": "^6.0.0",
    "@ionic/angular": "^7.5.0",
    "@ngx-translate/core": "^15.0.0",
    "@sentry/angular-ivy": "^7.114.0",
    "@sentry/capacitor": "^0.18.0",
    "@trapezedev/configure": "^7.0.10",
    "crypto-js": "^4.2.0",
    "date-fns": "^3.6.0",
    "ionicons": "^7.2.1",
    "jsencrypt": "^3.3.2",
    "rxjs": "~7.8.0",
    "swiper": "^11.0.6",
    "tslib": "^2.3.0",
    "zone.js": "~0.14.2"
  },
  "devDependencies": {
    "@angular-devkit/build-angular": "^17.3.0",
    "@angular-eslint/builder": "^17.3.0",
    "@angular-eslint/eslint-plugin": "^17.3.0",
    "@angular-eslint/eslint-plugin-template": "^17.3.0",
    "@angular-eslint/schematics": "^17.3.0",
    "@angular-eslint/template-parser": "^17.3.0",
    "@angular/compiler-cli": "^17.3.2",
    "@angular/language-service": "^17.3.2",
    "@capacitor/assets": "^3.0.5",
    "@capacitor/cli": "^6.0.0",
    "@cypress/schematic": "^2.5.2",
    "@ionic/angular-toolkit": "^11.0.1",
    "@sentry/cli": "^2.32.1",
    "@sentry/wizard": "^3.23.0",
    "@types/crypto-js": "^4.2.1",
    "@types/jasmine": "~5.1.0",
    "@typescript-eslint/eslint-plugin": "^6.4.0",
    "@typescript-eslint/parser": "^6.4.0",
    "cypress": "^13.13.1",
    "eslint": "^8.57.0",
    "eslint-config-prettier": "^9.1.0",
    "eslint-config-standard-with-typescript": "^43.0.1",
    "eslint-plugin-import": "^2.29.1",
    "eslint-plugin-jsdoc": "^48.2.2",
    "eslint-plugin-n": "^16.6.2",
    "eslint-plugin-prefer-arrow": "1.2.3",
    "eslint-plugin-prettier": "^5.1.3",
    "eslint-plugin-promise": "^6.1.1",
    "husky": "^9.0.11",
    "jasmine-core": "~5.1.0",
    "jasmine-spec-reporter": "~5.0.0",
    "karma": "~6.4.0",
    "karma-chrome-launcher": "~3.2.0",
    "karma-coverage": "~2.2.0",
    "karma-jasmine": "~5.1.0",
    "karma-jasmine-html-reporter": "~2.1.0",
    "lint-staged": "^15.2.2",
    "prettier": "^3.2.5",
    "prettier-eslint": "^16.3.0",
    "stylelint": "^16.6.1",
    "stylelint-config-standard": "^36.0.0",
    "stylelint-config-standard-scss": "^13.1.0",
    "ts-node": "^8.3.0",
    "typescript": "~5.2.2"
  },
  "description": "An Ionic project",
  "resolutions": {
    "@sentry/angular": "7.114.0"
  }
}
